import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import styles from './styles.module.css'
import {OnBoardingRoutesEnum, onBoardingScreens, onBoardingScreensPartMenu} from "../enums/onBoardingEnum";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import {ColorsEnum} from "../enums/ColorsEnum";
import './OnBoarding.css'
import ProgressBar from "./ProgressBar/ProgressBar";
import Goals from "../components/OnBoarding/Goals/Goals";
import Gender from "../components/OnBoarding/Gender/Gender";
import Age from "../components/OnBoarding/Age/Age";
import Height from "../components/OnBoarding/Height/Height";
import Weight from "../components/OnBoarding/Weight/Weight";
import TargetWeight from "../components/OnBoarding/TargetWeight/TargetWeight";
import Activity from "../components/OnBoarding/Activity/Activity";
import Speed from "../components/OnBoarding/Speed/Speed";
import Diets from "../components/OnBoarding/Diets/Diets";
import SingleDiet from "../components/OnBoarding/SingleDiet/SingleDiet";
import Cuisines from "../components/OnBoarding/Cuisines/Cuisines";
import CookingSkills from "../components/OnBoarding/CookingSkills/CookingSkills";
import Email from "../components/OnBoarding/Email/Email";
import SlideTransition from "./SlideTransition/SlideTransition";
import axios from "axios";
import {MayorSystem, setMayorSystem} from "../store/Options/OptionsSlice";
import {useAppDispatch, useAppSelector} from "../store/types";
import Dislikes from "../components/OnBoarding/Dislikes/Dislikes";
import DislikesModal from "../components/OnBoarding/Dislikes/DislikesModal/DislikesModal";
import SubscriptionPage from "../components/Subscription/SubscriptionPage";
import CustomHistoryListener from "../components/CustomHistoryListener";
import SuccessPayment from "../components/Payment/SuccessPayment/SuccessPayment";
import DislikesProductsModal from "../components/OnBoarding/Dislikes/DislikesProductsModal/DislikesProductsModal";
import MealPlanFormatScreen from "../components/OnBoarding/MealPlanFormatScreen/MealPlanFormatScreen";
import CustomMealPlanFormatScreen from "../components/OnBoarding/CustomMealPlanFormatScreen/CustomMealPlanFormatScreen";
import Loader from "../components/Loader/Loader";
import {clearState} from "../store/Subscription/SubscriptionSlice";
import {setClearOnboarding, setRouteName} from "../store/OnBoarding/OnBoardingSlice";
import WelcomePage from "../components/OnBoarding/WelcomePage/WelcomePage";
import Graph from "../components/OnBoarding/Graph/Graph";
import MessagePage from "../components/OnBoarding/Message/MessagePage";
import Summary from "../components/OnBoarding/Summary/Summary";
import PlanFeatures from "../components/OnBoarding/PlanFeatures/PlanFeatures";
import PrioritiesPage from "../components/OnBoarding/PrioritiesPage/PrioritiesPage";
import EatrUsersPage from "../components/OnBoarding/EatrUsersPage/EatrUsersPage";
import Helmet from "react-helmet";
import {CuisinesList} from "../components/OnBoarding/Cuisines/cuisinesList";
import {commonProductsList, productsList} from "../components/OnBoarding/Dislikes/productsList";
import {SkillsList} from "../components/OnBoarding/CookingSkills/skills";
import {
  menNotUnder30,
  menUnder30,
  womanNotUnder30,
  womanUnder30
} from "../components/OnBoarding/EatrUsersPage/usersList";
import {getImageLinkWithResize} from "../helper/appHelper";
import {ImageSizeEnum} from "../hooks/appEnum";
import LoseTwice from "../components/OnBoarding/LoseTwice/LoseTwice";
import NotFoundScreen from "../pages/NotFoundPage";


function getCurrentRouteKey(): string | null {
  const pathnames = window.location.pathname.split('/').filter((pathname) => pathname);
  return pathnames[pathnames.length - 1];
}

const OnBoardingLayout: React.FC = (props) => {
  const dispatch = useAppDispatch();
  const { diets } = useAppSelector(state => state.onBoarding)
  const [currentpage, setCurrentpage] = useState('')
  const [currentLocation, setCurrentLocation] = useState<number>(0);
  const [showProgress, setShowProgress] = useState<boolean>(false)
  const [showBack, setShowBack] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null)
  // useEffect(() => {
  //   console.log(history)
  //   if (history.length <= 2 && window.location.pathname.includes('onboarding')) {
  //     dispatch(clearState())
  //     dispatch(setClearOnboarding())
  //     history.push('/')
  //   }
  // }, []);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0,0);
    }
  }, [location]);
  useEffect(() => {
    const isVisited = localStorage.getItem('isVisited');

    if (!isVisited) {
      localStorage.setItem('isVisited', 'true');
      history.replace('/');
    }

    if (history.length === 1) {
      history.push('/')
    }
  }, [history]);

  function goBack() {
    const routeKey = getCurrentRouteKey();
    if (routeKey === 'onboarding' || history.length <= 2 || routeKey === 'welcome' || routeKey === 'goals') {
      history.replace('/')
    } else {
      history.goBack()
    }
  }

  useEffect(() => {
    const routeKey = getCurrentRouteKey();
    if (routeKey) {
      dispatch(setRouteName(routeKey))
      setCurrentpage(routeKey)
      localStorage.setItem('lastPage', routeKey);
    }
    const index = onBoardingScreens.findIndex(el => el === routeKey)
    if (index !== -1) {
      const position = Math.round(((index + 1) * 100) / onBoardingScreens.length)
      setShowProgress(true)
      setCurrentLocation(position)
    } else {
      const indexPart = onBoardingScreensPartMenu.findIndex(el => el === routeKey)
      setShowBack(indexPart !== -1)
      setShowProgress(false)
    }

    if (routeKey === 'goals') {
      setShowProgress(true)
    }

    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search')
    if (search) {
      if (search === 'true') {
        setShowProgress(false)
      }
    }

    if (routeKey === 'onboarding') {
      history.push('/')
    }

  }, [history, location]);

  const getGeoInfo = useCallback(() => {
    const USES_IMPERIAL: string[] = ['LR', 'MM', 'US'];
    const userMayorSystem = localStorage.getItem('mayorSystem')
    if (userMayorSystem) {
      dispatch(
        setMayorSystem(userMayorSystem as MayorSystem)
      );
    } else {
      axios
        .get('https://ipapi.co/json/')
        .then(response => {
          let data = response.data;
          const value = !USES_IMPERIAL.includes(data.country)
            ? MayorSystem.METRIC
            : MayorSystem.IMPERIAL
          localStorage.setItem('mayorSystem', value)
          dispatch(
            setMayorSystem(value)
          );
        })
        .catch(error => {
          // console.log(error);
        });
    }

  }, [dispatch]);

  const images = useMemo(() => {
    const manImage = require("../assets/summary/SummaryMale.webp");
    const womanImage = require("../assets/summary/SummaryFemale.webp");
    const list: any[] = []
    CuisinesList.forEach(el => {
      list.push(el.image)
    })
    productsList.forEach(el => {
      list.push(el.image)
    })
    SkillsList.forEach(el => {
      list.push(el.image)
    })
    commonProductsList.forEach(el => {
      list.push(el.image)
    })
    menNotUnder30.forEach(el => {
      list.push(el)
    })
    menUnder30.forEach(el => {
      list.push(el)
    })
    womanUnder30.forEach(el => {
      list.push(el)
    })
    womanNotUnder30.forEach(el => {
      list.push(el)
    })
    if (diets.length > 0) {
      diets.forEach(el => {
        list.push(getImageLinkWithResize(el.imageUrl, ImageSizeEnum.M))
      })
    }
    list.push(manImage)
    list.push(womanImage)
    list.push(require('../assets/images/FoodFirst_cropped.webp'))
    list.push(require('../assets/images/FoodSecond_cropped.webp'))
    list.push(require('../assets/images/mealPlan/lunch.webp'))
    list.push(require('../assets/images/mealPlan/dinner.webp'))
    list.push(require('../assets/images/mealPlan/breakfast.webp'))
    return list
  }, [diets])

  useEffect(() => {
    getGeoInfo()
  }, [getGeoInfo])

  return (
    <div className={[styles.container, currentpage].join(' ')}>
      <Helmet>
        {images.map((el, index) => {
          return (
            <link rel="preload" as="image" href={el} key={index}/>
          )
        })}
      </Helmet>
      <div className={styles.row}>
        <div style={{cursor: 'pointer'}} onClick={() => history.push('/')} className={styles.logo}>
          <img src="/logo.png" alt=""/>
        </div>
        <div ref={containerRef} className={[styles.onboardingContainer, styles[currentpage]].join(' ')}>
          <>
          {showProgress ? (
            <div className={styles.navBar}>
              <div onClick={goBack} className={styles.backButton}>
                <ArrowLeftIcon stroke={ColorsEnum.GRAY_BLACK}/>
              </div>
              <div style={{
                flex: 1
              }}>
                <ProgressBar percentage={currentLocation}/>
              </div>
            </div>
          ) : (showBack ? (
              <div className={styles.navBar}>
                <div onClick={goBack} className={styles.backButton}>
                  <ArrowLeftIcon stroke={ColorsEnum.GRAY_BLACK}/>
                </div>
              </div>
          ) : null)}
          </>
          <CustomHistoryListener />
          <Route
            render={(props) => (
              <SlideTransition {...props}>
                <Switch location={location}>
                  <Route key="users" path={OnBoardingRoutesEnum.USERS_PAGE} component={EatrUsersPage}/>
                  <Route key="lose-twice" path={OnBoardingRoutesEnum.LOSE_TWICE_PAGE} component={LoseTwice}/>
                  <Route key="welcome" path={OnBoardingRoutesEnum.WELCOME_PAGE} component={WelcomePage}/>
                  <Route key="summary" path={OnBoardingRoutesEnum.SUMMARY_PAGE} component={Summary}/>
                  <Route key="graph" path={OnBoardingRoutesEnum.GRAPH} component={Graph}/>
                  <Route key="planFeatures" path={OnBoardingRoutesEnum.PLAN_FEATURES_PAGE} component={PlanFeatures}/>
                  <Route key="priorities" path={OnBoardingRoutesEnum.PRIORITIES_PAGE} component={PrioritiesPage}/>
                  <Route key="message" path={OnBoardingRoutesEnum.MESSAGE_PAGE} component={MessagePage}/>
                  <Route key="payment" path={OnBoardingRoutesEnum.PAYMENT} component={SubscriptionPage}/>
                  <Route key="payment" path={OnBoardingRoutesEnum.PAYMENT_SUCCESS} component={SuccessPayment}/>
                  <Route key="gender" path={OnBoardingRoutesEnum.GENDER} component={Gender}/>
                  <Route key="age" path={OnBoardingRoutesEnum.AGE} component={Age}/>
                  <Route key="height" path={OnBoardingRoutesEnum.HEIGHT} component={Height}/>
                  <Route key="weight" path={OnBoardingRoutesEnum.WEIGHT} component={Weight}/>
                  <Route key="target-weight" path={OnBoardingRoutesEnum.TARGET_WEIGHT} component={TargetWeight}/>
                  <Route key="activity" path={OnBoardingRoutesEnum.ACTIVITY} component={Activity}/>
                  <Route key="speed" path={OnBoardingRoutesEnum.SPEED} component={Speed}/>
                  <Route key="diet/:id" path={OnBoardingRoutesEnum.SINGLE_DIET} component={SingleDiet}/>
                  <Route key="diet" path={OnBoardingRoutesEnum.DIET} component={Diets}/>
                  <Route key="dislikesSearch" path={OnBoardingRoutesEnum.DISLIKES_SEARCH} component={DislikesModal}/>
                  <Route key="dislikesProductsModal" path={OnBoardingRoutesEnum.DISLIKES_PRODUCTS} component={DislikesProductsModal}/>
                  <Route key="dislikes" path={OnBoardingRoutesEnum.DISLIKES} component={Dislikes}/>
                  <Route key="cuisines" path={OnBoardingRoutesEnum.CUISINES} component={Cuisines}/>
                  <Route key="cooking-skill" path={OnBoardingRoutesEnum.COOKING_SKILL} component={CookingSkills}/>
                  <Route key="meal-plan" path={OnBoardingRoutesEnum.MEAL_PLAN} component={MealPlanFormatScreen}/>
                  <Route key="custom-meal-plan" path={OnBoardingRoutesEnum.CUSTOM_MEAL_PLAN} component={CustomMealPlanFormatScreen}/>
                  <Route key="email" path={OnBoardingRoutesEnum.EMAIL} component={Email}/>
                  <Route key="goals" path={OnBoardingRoutesEnum.GOALS} component={Goals}/>
                  <Route key="loader" path={OnBoardingRoutesEnum.LOADER} component={Loader}/>
                  <Route path="*" component={NotFoundScreen}/>
                </Switch>
              </SlideTransition>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default OnBoardingLayout
