import {useState} from "react";
import {LoginParams} from "../../../store/UserSlice/UserSlice";
import {defHttp} from "../../../api";
import mixpanel from "mixpanel-browser";

export const useEvent = () => {
  const [user, setUser] = useState<LoginParams | null>(null);
  const [error, setError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const getUserData = async (email: string, subId: string) => {
    setError(false)
    setErrorMessage(null)
    setLoading(true)
    try {
      const res = await defHttp.post(`/api/v2/recipe/get-token`, {
        email,
      })
      if (res.data) {
        if (res.data.token) {
          await localStorage.setItem('token', res.data.token);
          setUser({
            email: res.data.email,
            appleId: res.data.appleId,
            token: res.data.token,
          })
          setLoading(false)
          mixpanel.identify(res.data.email)
          mixpanel.people.set('$email', res.data.email);
          let canCreateSubscribe = true
          const userSubsList = await defHttp.get('/api/v2/subscriptions');
          console.log(userSubsList)
          if (userSubsList.data) {
            userSubsList.data.forEach((item: any) => {
              if (canCreateSubscribe) {
                item.items.forEach((sub: any) => {
                  console.log(sub)
                  if (sub.priceId === subId) {
                    canCreateSubscribe = false
                  }
                })
              }
            })
          }

            console.log('canCreateSubscribe', canCreateSubscribe)
          if (!canCreateSubscribe) {
            setError(true)
            setErrorMessage('You already have a subscription, check the app and tap on restore subscription')
          }
        }
      }
    } catch (e) {
      console.log(e)
      setUser(null)
      setError(true)
      setLoading(false)
    }
  }
  return {
    errorMessage,
    loading,
    error,
    user,
    getUserData
  }
}
