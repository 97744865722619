import React from 'react';

const CitationsAndSourcesPage: React.FC = () => {
  return (
    <div>
      <h1>Citations and Sources</h1>
      <p>
        This document is meant to help you understand what information we collect with Eatr and how we use it. Throughout this document, when we refer to Eatr, we’re
      </p>

      <div>
        <h2>How to Estimate Your BMR</h2>
        <p>The Harris-Benedict formula estimates BMR by considering weight, height, age, and gender. There are 2 formulas used to calculate BMR, in [kcal / 24hrs] for men and women respectively:</p>
        <ul>
          <li>BMR for Men = 66.47 + (13.75 * weight [kg]) + (5.003 * size [cm]) − (6.755 * age [years])</li>
          <li>BMR for Women = 655.1 + (9.563 * weight [kg]) + (1.85 * size [cm]) − (4.676 * age [years])</li>
        </ul>
        <div>Learn more about BMR.</div>
      </div>

      <div>
        <h2>Adjusting for Activity Level</h2>
        <p>Your Total Daily Energy Expenditure (TDEE) is adjusted based on your activity level:</p>
        <ul>
          <li>Low activity: BMR * 1.2</li>
          <li>Medium activity: BMR * 1.375</li>
          <li>High activity: BMR * 1.55</li>
          <li>Very high activity: BMR * 1.725</li>
          <li>Extra activity: BMR * 1.9</li>
        </ul>
        <div>Learn more about TDEE.</div>
      </div>

      <div>
        <h2>Diet Plans</h2>
        <div>
          <div>Balanced</div>
          <div>This diet follows a macronutrient ratio of 55% carbs, 20% protein, and 25% fat, adhering to USDA and WHO
            guidelines. The balanced diet plan includes a variety of foods to ensure all essential nutrients are
            consumed, promoting overall health and well-being.
          </div>
        </div>
        <div>
          <div>Pescatarian</div>
          <div>This diet uses a similar macronutrient ratio to the balanced diet (55% carbs, 20% protein, and 25% fat) and excludes meat. The pescatarian diet includes fish and other seafood as primary protein sources while maintaining the balanced macronutrient ratio.</div>
        </div>
        <div>
          <div>Flexitarian</div>
          <div>This diet excludes red meat and focuses on plant-based recipes while maintaining the balanced diet ratio of 55% carbs, 20% protein, and 25% fat. The flexitarian diet is mostly vegetarian but allows for occasional meat and fish consumption, offering flexibility and promoting a plant-based lifestyle.</div>
        </div>
        <div>
          <div>Vegetarian</div>
          <div>This diet uses a similar macronutrient ratio to the balanced diet (55% carbs, 20% protein, and 25% fat) and excludes meat and fish. The vegetarian diet focuses on plant-based foods and includes dairy and eggs, providing a balanced intake of nutrients without meat and fish.</div>
        </div>
        <div>
          <div>Low-carb</div>
          <div>This diet features 20% carbs, 25% protein, and 55% fat for a moderate low-carb, high-protein approach. The low-carb diet reduces carbohydrate intake while increasing protein and fat, aiding in weight loss and blood sugar management.</div>
        </div>
        <div>
          <div>Keto</div>
          <div>This diet uses a macronutrient ratio of 10% carbs, 25% protein, and 65% fat, making it a very low-carb, high-protein approach. The ketogenic diet emphasizes very low carb intake to induce ketosis, where the body burns fat for fuel, aiding in weight loss and energy levels.</div>
        </div>
        <div>
          <div>Mediterranean</div>
          <div>This diet follows a 45% carb, 20% protein, and 35% fat ratio, emphasizing healthy fats and excluding red meat. The Mediterranean diet is rich in fruits, vegetables, whole grains, and healthy fats like olive oil, promoting heart health and longevity.</div>
        </div>
        <div>
          <div>Clean Eating</div>
          <div>This diet uses a similar macronutrient ratio to the balanced diet (55% carbs, 20% protein, and 25% fat) and excludes processed foods and added sugars. The clean eating plan focuses on whole, unprocessed foods, avoiding additives and preservatives to improve health and well-being.</div>
        </div>
        <div>
          <div>Paleo</div>
          <div>This diet uses a moderate low-carb, high-protein approach with 20% carbs, 30% protein, and 50% fat, excluding farmed or processed foods. The paleo diet mimics the eating habits of ancient humans, focusing on whole foods like meat, fish, fruits, and vegetables while avoiding grains and dairy.</div>
        </div>
        <div>
          <div>Vegan</div>
          <div>This diet uses a macronutrient ratio of 55% carbs, 15% protein, and 30% fat, similar to the balanced diet but excludes all animal-based foods. The vegan diet excludes all animal products, relying on plant-based foods to provide essential nutrients and promote overall health.</div>
        </div>
      </div>

      <div>
        <h2>Eatr's Recommendation Goals</h2>
        <p>Eatr's calorie targets, nutritional goals, and Meal Plan recommendations are personalized based on an individual's gender, health condition, and stage of life.</p>
        <ul>
          <li>
            <p>Calorie Goal</p>
            <p>Eatr's calorie targets consist of BMR (Basal Metabolic Rate) and a calorie deficit or proficit. To ensure healthy weight goals achievement, we offer a calorie deficit or proficit range of 150-500 calories. This ensures that our users can feel satiated while still effectively achieving weight goals.</p>
            <p>Learn more about Calorie Deficit.</p>
          </li>
          <li>
            <p>Nutrition Goal</p>
            <p>Balanced nutrition is key to healthy achieving weight goals. We recommend corresponding nutritional target values to our users based on different physical conditions and calorie goals.</p>
            <p>Regarding the Calculation of Nutritional Targets.</p>
          </li>
          <li>
            <p>Heart Health</p>
            <p>We recommend that individuals trying to improve their health and lose weight consume lower amounts of fat, sodium, and cholesterol. This is beneficial for heart health. Fats, Sodium, and Cholesterol are related to heart health.</p>
          </li>
        </ul>
      </div>

      <div>
        <h2>Contact Information:</h2>
        <p>Any questions or feedback? Please feel free to reach out to contact@eatr.com – we're always happy to hear your thoughts!</p>
      </div>
    </div>
  )
}

export default CitationsAndSourcesPage;
